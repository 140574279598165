import React, { useEffect } from 'react';
import BundlesTable from '../components/BundlesTable';

const BundlesPage: React.FC = () => {
    useEffect(() => {
        document.title = "DirectCRM Зв'язки";
    }, []);

    return (
        <div>
            <h1>Зв'язки лінків</h1>
            <BundlesTable />
        </div>
    );
};

export default BundlesPage;