import axios from 'axios';
import { getToken, removeToken } from '../utils/auth';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_KEITARO_API_URL;
const axiosInstance = axios.create({
  baseURL: API_URL, 
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers!['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Додаємо перехоплювач відповідей
axiosInstance.interceptors.response.use(
  (response) => {
    // Якщо відповідь успішна, просто повертаємо її
    return response;
  },
  (error) => {
    // Перевіряємо, чи є відповідь від сервера
    if (error.response) {
      const status = error.response.status;

      if (status === 401) {
        // Видаляємо токен з локального сховища
        removeToken(); // Переконайтеся, що ця функція існує в '../utils/auth'

        // Відображаємо повідомлення (опціонально)
        message.warning('Ваша сесія сплила. Будь ласка, авторизуйтесь знову.');

        // Перенаправляємо користувача на сторінку авторизації
        window.location.href = '/login'; // Змініть на URL вашої сторінки авторизації
      }
    }

    // Повертаємо відхилений проміс з помилкою
    return Promise.reject(error);
  }
);

export default axiosInstance;