import React, { useState, useContext } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { AuthContext } from '../context/AuthContext';
import axios from '../api/axiosInstance';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaKey: string = process.env.REACT_APP_RECAPTCHA_KEY;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await axios.post('/api/users/login', {
        username: values.username,
        password: values.password,
        captcha: captchaValue,
      });

      const { token, role, username } = response.data;

      authContext?.login(token, role, username);
      message.success('Успішний вхід');

      // Перенаправляємо на домашню сторінку
      navigate('/');
    } catch (error: any) {
      if (error.response?.data?.requireCaptcha) {
        setShowCaptcha(true); // Показати CAPTCHA
        message.warning("Введіть капчу для продовження.");
      } else {
        if (error.response?.data?.message) {
          message.error(error.response?.data?.message);
        } else {
          message.error('Невірне ім\'я користувача або пароль');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      name="login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ maxWidth: 300, margin: '0 auto' }}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Будь ласка, введіть ім\'я користувача!' }]}
      >
        <Input placeholder="Ім'я користувача" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Будь ласка, введіть пароль!' }]}
      >
        <Input.Password placeholder="Пароль" />
      </Form.Item>

      <Form.Item>
        <Checkbox>Запам'ятати мене</Checkbox>
      </Form.Item>

      {showCaptcha && (
        <Form.Item>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={handleCaptchaChange} />
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Увійти
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;