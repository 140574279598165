import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRole?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRole }) => {
  const authContext = useContext(AuthContext);

  if (!authContext?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && authContext.userRole !== requiredRole) {
    return <Navigate to="/" />; // Або на сторінку "Доступ заборонено"
  }

  return <>{children}</>;
};

export default PrivateRoute;