import React, { useEffect } from 'react';
import SourcesTable from '../components/SourcesTable';

const SourcesPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Джерела";
  }, []);

  return (
    <div>
      <h1>Джерела</h1>
      <SourcesTable />
    </div>
  );
};

export default SourcesPage;