import React, { useContext } from 'react';
import { Layout, Menu, Dropdown, Typography, Avatar  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { DownOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  if (!authContext?.isAuthenticated && authContext?.userRole === null && authContext?.userRole === undefined) {
    navigate('/login');
    return null;
  }

  const handleLogout = () => {
    authContext?.logout();
    navigate('/login');
  };

  const menuItems = [
    { key: '1', label: 'Посилання', path: '/', roles: ['user', 'admin']},
    { key: '2', label: 'Дублі', path: '/unique-leads', roles: ['user', 'admin'] },
    { key: '3', label: 'Капа', path: '/caps', roles: ['user', 'admin'] },
    { key: '4', label: 'Користувачі', path: '/users', roles: ['admin'] },
    { key: '5', label: 'Джерела', path: '/sources', roles: ['admin'] },
    { key: '6', label: 'Продукти', path: '/products', roles: ['admin'] },
    { key: '7', label: 'Групи продуктів', path: '/product-groups', roles: ['admin'] },
    { key: '8', label: 'Зв\'язки лінків', path: '/bundles', roles: ['admin'] },
    { key: '9', label: 'Команди', path: '/teams', roles: ['admin'] },
  ];


  const userRole = authContext?.userRole ?? '';
  
  const filteredMenuItems = menuItems.filter((item) => {
    if (userRole) {
      return item.roles.includes(userRole);
    }
    return false;
  });


  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Вийти
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Menu theme="dark" mode="horizontal" selectable={false} disabledOverflow={true}>
            {filteredMenuItems.map((item) => (
              <Menu.Item key={item.key}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
          <div style={{ color: 'white', display: 'flex', alignItems: 'center'}}>
            <Dropdown overlay={userMenu} trigger={['hover']}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Avatar style={{ backgroundColor: '#87d068', marginRight: '8px' }}>
                    {authContext?.username?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography.Text style={{ color: 'white' }}>
                    {authContext?.username} <DownOutlined />
                </Typography.Text>
                </div>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content style={{ padding: '20px' }}>
        {children}
      </Content>
    </Layout>
  );
};

export default MainLayout;