import React, { useEffect } from 'react';
import UniqueLeads from '../components/UniqueLead';

const UniqueLeadPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Перевірка унікальних лідів";
  }, []);

  return (
    <div>
      <h1>Перевірка унікальних лідів</h1>
      <UniqueLeads/>
    </div>
  );
};

export default UniqueLeadPage;