import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Modal, Form, Input, message, Popconfirm, ColorPicker, Space, Select } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColorPickerProps, GetProp } from 'antd';
import axios from '../api/axiosInstance';

interface ProductGroup {
  id: string;
  name: string;
  color: string;
  comment?: string;
}

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string | { cleared: any }>;
type Format = GetProp<ColorPickerProps, 'format'>;

const ProductGroupsPage: React.FC = () => {
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentGroup, setCurrentGroup] = useState<ProductGroup | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [colorHex, setColorHex] = useState<Color>('#1677ff');
  const [formatHex, setFormatHex] = useState<Format | undefined>('hex');

  const [filterGroupId, setFilterGroupId] = useState<string | undefined>(undefined);

  const hexString = React.useMemo<string>(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex],
  );
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "DirectCRM Групи продуктів";
  }, []);
  
  useEffect(() => {
    fetchProductGroups();
  }, []);

  const fetchProductGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/product-groups');
      setProductGroups(response.data);
    } catch (error) {
      message.error('Не вдалося отримати групи продуктів');
    } finally {
      setLoading(false);
    }
  };

  const showModal = (group?: ProductGroup) => {
    setIsModalVisible(true);
    if (group) {
      setIsEditMode(true);
      setCurrentGroup(group);
      setColorHex(group.color);
      form.setFieldsValue({
        name: group.name,
        color: group.color,
        comment: group.comment,
      });
    } else {
      setIsEditMode(false);
      setCurrentGroup(null);
      form.resetFields();
      setColorHex('#1677ff');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentGroup(null);
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    try {
      if (isEditMode && currentGroup) {
        // Оновлення групи продуктів
        await axios.put(`/api/product-groups/${currentGroup.id}`, values);
        message.success('Групу продуктів оновлено успішно');
      } else {
        // Створення нової групи продуктів
        await axios.post('/api/product-groups', values);
        message.success('Групу продуктів створено успішно');
      }
      fetchProductGroups();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Не вдалося зберегти групу продуктів');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/api/product-groups/${id}`);
      message.success('Групу продуктів видалено успішно');
      fetchProductGroups();
    } catch (error) {
      message.error('Не вдалося видалити групу продуктів');
    }
  };

  // Функція для визначення, чи є колір темним
  const isColorDark = (hexColor: string): boolean => {
    const color = hexColor.replace('#', '');

    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness < 128;
  };

  const filteredData = useMemo(() => {
    if (!filterGroupId) {
      return productGroups;
    }
    return productGroups.filter(pg => pg.id === filterGroupId);
  }, [productGroups, filterGroupId]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: ProductGroup) => {
        const backgroundColor = record.color;
        const isDark = isColorDark(backgroundColor);
        const textColor = isDark ? '#FFFFFF' : '#000000';

        return (
          <div
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              padding: '8px',
              borderRadius: '4px',
              textAlign: 'center',
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'Коментар',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (_: any, record: ProductGroup) => (
        <div>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => showModal(record)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="Ви впевнені, що хочете видалити цю групу продуктів?"
            onConfirm={() => handleDelete(record.id)}
            okText="Так"
            cancelText="Ні"
          >
            <Button type="link" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <h1>Групи продуктів</h1>
        <Space style={{ marginBottom: 16 }} wrap>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          >
            Створити
          </Button>
          <Select
            showSearch
            allowClear
            placeholder="Фільтр по групі продуктів"
            style={{ width: 200 }}
            value={filterGroupId}
            onChange={(value) => setFilterGroupId(value)}
            filterOption={(input, option) =>
              ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={productGroups.map(pg => ({
              label: pg.name,
              value: pg.id
            }))}
          />
        </Space>
        <Table
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {
              setPageSize(size);
              setCurrentPage(1); // Повертаємося на першу сторінку при зміні розміру
            },
            onChange: (page) => setCurrentPage(page),
          }}
        />

        <Modal
          title={isEditMode ? 'Оновити групу продуктів' : 'Створити групу продуктів'}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              name="name"
              label="Назва"
              rules={[{ required: true, message: 'Будь ласка, введіть назву' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="color"
              label="Колір (HEX)"
              initialValue="#1677ff"
              rules={[
                {
                  required: true,
                  message: 'Будь ласка, виберіть або введіть колір у форматі HEX',
                },
              ]}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input
                  value={hexString}
                  readOnly
                />
                <ColorPicker
                  format={formatHex}
                  value={colorHex}
                  onChange={(newColor) => {
                    const hex = newColor.toHexString(); // Отримуємо нове значення кольору
                    setColorHex(hex); // Оновлюємо локальний стан
                    form.setFieldValue('color', hex); // Оновлюємо значення у формі
                  }} // Оновлюємо стан при зміні кольору
                  onFormatChange={setFormatHex} // Оновлюємо формат
                />
              </Space.Compact>
            </Form.Item>
            <Form.Item name="comment" label="Коментар">
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {isEditMode ? 'Оновити' : 'Створити'}
              </Button>
              <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
                Скасувати
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default ProductGroupsPage;
