import React, { useEffect } from 'react';
import LinkCards from '../components/LinkCards';

const OffersPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Посилання";
  }, []);

  return (
    <div>
      <h1>Посилання</h1>
      <LinkCards />
    </div>
  );
};

export default OffersPage;