import React, { createContext, useState } from 'react';
import { getToken, getUserRole, getUsername, setToken, setUserRole, setUsername, removeToken, removeUserRole, removeUsername } from '../utils/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  userRole: string | null;
  username: string | null;
  login: (token: string, role: string, username: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Ініціалізуємо стани з `localStorage`
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = getToken();
    return !!token;
  });

  const [userRole, setUserRoleState] = useState<string | null>(() => {
    return getUserRole();
  });

  const [username, setUsernameState] = useState<string | null>(() => {
    return getUsername();
  });

  const login = (token: string, role: string, username: string) => {
    setToken(token);
    setUserRole(role);
    setUsername(username);
    setIsAuthenticated(true);
    setUserRoleState(role);
    setUsernameState(username);
  };

  const logout = () => {
    removeToken();
    removeUserRole();
    removeUsername();
    setIsAuthenticated(false);
    setUserRoleState(null);
    setUsernameState(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, username, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};