import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Button, message, Select, InputNumber, Input } from 'antd';
import axios from '../api/axiosInstance';

const { Option } = Select;

const SourcesTable: React.FC = () => {
  const [sources, setSources] = useState<any[]>([]);
  const [modifiedSources, setModifiedSources] = useState<any[]>([]); // Модифіковані джерела для збереження
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  // Стани для пагінації
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    fetchSources();
  }, []);

  // Отримуємо всі джерела
  const fetchSources = async () => {
    setLoading(true);
    try {
      // Отримуємо основні дані про джерела
      const sourcesResponse = await axios.get('/api/sources/');
  
      setSources(sourcesResponse.data);
    } catch (error) {
      message.error('Не вдалося отримати список джерел');
    } finally {
      setLoading(false);
    }
  };
  

  // Обробка змін у чекбоксі
  const handleCheckboxChange = (sourceId: number, checked: boolean) => {
    // Оновлюємо стан джерел в масиві sources
    setSources((prevSources) =>
      prevSources.map((source) =>
        source.sourceId === sourceId ? { ...source, isActive: checked } : source
      )
    );
  
    // Оновлюємо модифіковані джерела в масиві modifiedSources
    setModifiedSources((prevModifiedSources) => {
      const existingSource = prevModifiedSources.find((source) => source.sourceId === sourceId);
      if (existingSource) {
        // Якщо джерело вже модифіковане, оновлюємо його
        return prevModifiedSources.map((source) =>
          source.sourceId === sourceId ? { ...source, isActive: checked } : source
        );
      } else {
        // Якщо джерела ще немає в модифікованих, додаємо його
        return [...prevModifiedSources, { sourceId, isActive: checked }];
      }
    });
  
    setIsSaveDisabled(false); // Дозволяємо кнопку збереження
  };
  
  const handleCampaignIdChange = (sourceId: number, campaignSampleId: number) => {
    setSources((prevSources) =>
      prevSources.map((source) =>
        source.sourceId === sourceId ? { ...source, campaignSampleId } : source
      )
    );

    setModifiedSources((prevModifiedSources) => {
      const existingSource = prevModifiedSources.find((source) => source.sourceId === sourceId);
      if (existingSource) {
        return prevModifiedSources.map((source) =>
          source.sourceId === sourceId ? { ...source, campaignSampleId } : source
        );
      } else {
        return [...prevModifiedSources, { sourceId, campaignSampleId }];
      }
    });

    setIsSaveDisabled(false);
  };

  const handleParamNameChange = (sourceId: number, paramName: string) => {
    setSources((prevSources) =>
      prevSources.map((source) =>
        source.sourceId === sourceId ? { ...source, paramName } : source
      )
    );

    setModifiedSources((prevModifiedSources) => {
      const existingSource = prevModifiedSources.find((source) => source.sourceId === sourceId);
      if (existingSource) {
        return prevModifiedSources.map((source) =>
          source.sourceId === sourceId ? { ...source, paramName } : source
        );
      } else {
        return [...prevModifiedSources, { sourceId, paramName }];
      }
    });

    setIsSaveDisabled(false);
  };

  // Логіка для вибору "Унікальний для"
  const handleUniqueForChange = (sourceId: number, uniqueFor: number) => {
    setSources((prevSources) =>
      prevSources.map((source) =>
        source.sourceId === sourceId ? { ...source, uniqueFor } : source
      )
    );

    setModifiedSources((prevModifiedSources) => {
      const existingSource = prevModifiedSources.find((source) => source.sourceId === sourceId);
      if (existingSource) {
        return prevModifiedSources.map((source) =>
          source.sourceId === sourceId ? { ...source, uniqueFor } : source
        );
      } else {
        return [...prevModifiedSources, { sourceId, uniqueFor }];
      }
    });

    setIsSaveDisabled(false); // Вмикаємо кнопку збереження
  };

  // Збереження змін
  const handleSave = async () => {
    try {
      await axios.post('/api/sources/', { sources: modifiedSources });
      message.success('Джерела успішно оновлено');
      setModifiedSources([]);
      setIsSaveDisabled(true);
      fetchSources(); // Оновлюємо список після збереження
    } catch (error) {
      message.error('Не вдалося зберегти джерела');
    }
  };

  const handleRefresh = async () => {
    await axios.post('/api/sources/refresh');
    fetchSources();
    setModifiedSources([]);
    setIsSaveDisabled(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'sourceId',
      key: 'sourceId',
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Унікальний для',
      key: 'uniqueFor',
      render: (_: any, record: any) => (
        <Select
          value={record.uniqueFor || undefined}
          placeholder="Оберіть опцію" 
          onChange={(value) => handleUniqueForChange(record.sourceId, value)}
          style={{ width: 200 }}
        >
          <Option value={1}>Груп продуктів</Option>
          <Option value={2}>Команд</Option>
          <Option value={3}>1 на все</Option>
        </Select>
      ),
    },
    {
      title: 'ID кампанії-взірця',
      key: 'campaignSampleId',
      render: (_: any, record: any) => (
        <InputNumber
          min={0} // Вказуємо мінімальне значення
          value={record.campaignSampleId} // Підставляємо значення для поля
          onChange={(value) => handleCampaignIdChange(record.sourceId, value)} // Обробка зміни
        />
      ),
    },
    {
      title: 'Підпис параметру ліда',
      key: 'paramName',
      render: (_: any, record: any) => (
        <Input
          min={0} // Вказуємо мінімальне значення
          value={record.paramName} // Підставляємо значення для поля
          onChange={(e) => handleParamNameChange(record.sourceId, e.target.value)} // Обробка зміни
        />
      ),
    },
    {
      title: 'Активний',
      key: 'active',
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.isActive} // Використовуємо поле isActive з самого джерела
          onChange={(e) => handleCheckboxChange(record.sourceId, e.target.checked)}
        />
      ),
    }
  ];

  return (
    <div>
      <Button type="primary" onClick={handleSave} disabled={isSaveDisabled} style={{ marginRight: '8px' }}>
        Зберегти
      </Button>
      <Button onClick={handleRefresh}>Оновити</Button>
      <Table
        columns={columns}
        dataSource={sources}
        loading={loading}
        rowKey="sourceId"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
            setCurrentPage(1);
          },
          onChange: (page) => setCurrentPage(page),
        }}
        style={{ marginTop: '16px' }}
      />
    </div>
  );
};

export default SourcesTable;
