import React, { useEffect } from 'react';
import OffersTable from '../components/OffersTable';

const OffersPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Оффери";
  }, []);

  return (
    <div>
      <h1>Оффери</h1>
      <OffersTable />
    </div>
  );
};

export default OffersPage;