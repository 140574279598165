// components/UniqueLeads.tsx
import React, { useState } from 'react';
import { Input, Button, Table, Alert, Space } from 'antd';
import axios from '../api/axiosInstance';

interface LeadData {
  tester: string;
  ip: string;
  group: string;
  
}

const UniqueLeads: React.FC = () => {
  const [tester, setTester] = useState<string>('');
  const [leads, setLeads] = useState<LeadData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleCheck = async () => {
    if (!tester.trim()) {
      setError('Будь ласка, введіть значення tester');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.post('/api/unique-leads', { tester });
      setLeads(response.data);
    } catch (err: any) {
      setError(err.response?.data?.error || 'Невідома помилка');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Tester',
      dataIndex: 'tester',
      key: 'tester',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      render: (text: string) => (
        <>
          {text.split('\n').map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
        </>
      ),
    },
    /*{
      title: 'Device Model',
      dataIndex: 'device_model',
      key: 'device_model',
      render: (text: string) => (
        <>
          {text.split('\n').map((item, idx) => (
            <div key={idx}>{item}</div>
          ))}
        </>
      ),
    },*/
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Space style={{ marginBottom: '20px' }}>
        <Input
          placeholder="Введіть tester"
          value={tester}
          onChange={(e) => setTester(e.target.value)}
          style={{ width: '300px' }}
        />
        <Button type="primary" onClick={handleCheck} loading={loading}>
          Перевірити
        </Button>
      </Space>

      {error && (
        <Alert
          message="Помилка"
          description={error}
          type="error"
          showIcon
          closable
          style={{ marginBottom: '20px' }}
          onClose={() => setError('')}
        />
      )}

      {!loading && (
        leads.length > 0 && (
          <Table
            dataSource={leads}
            columns={columns}
            rowKey={(record) => record.ip}
          />
        )
      )}
    </div>
  );
};

export default UniqueLeads;
