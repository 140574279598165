// eventSourceInstance.ts
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getToken, removeToken } from '../utils/auth';
import { message } from 'antd';

const API_URL: string = process.env.REACT_APP_KEITARO_API_URL;

class EventSourceInstance {
  private baseURL: string;
  private eventSources: EventSourcePolyfill[];
  public interceptors: {
    request: ((options: any) => any)[];
    response: ((event: MessageEvent<any>) => void)[];
  };

  constructor() {
    this.baseURL = API_URL;
    this.eventSources = [];
    this.interceptors = {
      request: [],
      response: [],
    };
  }

  // Метод для додавання інтерсепторів запиту
  interceptRequest(interceptor: (options: any) => any) {
    this.interceptors.request.push(interceptor);
  }

  // Метод для додавання інтерсепторів відповіді
  interceptResponse(interceptor: (event: MessageEvent<any>) => void) {
    this.interceptors.response.push(interceptor);
  }

  // Метод для створення нового підключення EventSource
  create(url: string, options: any = {}) {
    const token = getToken();

    // Якщо URL відносний, додаємо baseURL
    const fullUrl = url.startsWith('http') ? url : `${this.baseURL}${url}`;

    // Застосування інтерсепторів запиту
    this.interceptors.request.forEach((interceptor) => {
      options = interceptor(options) || options;
    });

    // Додавання заголовків авторизації
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    const es = new EventSourcePolyfill(fullUrl, {
      ...options,
      headers,
    });

    const instance = this;

    // Оновіть es.onmessage
    es.onmessage = function (this: EventSourcePolyfill, event: MessageEvent<any>) {
      instance.interceptors.response.forEach((interceptor) => {
        interceptor(event);
      });
    };
    
    // Оновіть es.onerror
    es.onerror = function (this: EventSourcePolyfill, error: any) {
      if (error.status === 401) {
        removeToken();
        message.warning('Ваша сесія сплила. Будь ласка, авторизуйтесь знову.');
        window.location.href = '/login';
      } else {
        message.error('Помилка SSE, зупинка оновлення');
      }
      es.close();
    };

    this.eventSources.push(es);
    return es;
  }

  // Метод для закриття всіх підключень
  closeAll() {
    this.eventSources.forEach((es) => es.close());
    this.eventSources = [];
  }
}

const eventSourceInstance = new EventSourceInstance();

export default eventSourceInstance;
