import React from 'react';
import { Layout, Typography } from 'antd';
import LoginForm from '../components/LoginForm';

const { Header, Content } = Layout;
const { Title } = Typography;

const LoginPage: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header>
        <Title style={{ color: 'white', margin: 0 }}>DirectCRM</Title>
      </Header>
      <Content style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%', padding: '50px' }}>
          <LoginForm />
        </div>
      </Content>
    </Layout>
  );
};

export default LoginPage;