export const getToken = () => {
    return localStorage.getItem('token');
  };
  
  export const setToken = (token: string) => {
    localStorage.setItem('token', token);
  };
  
  export const removeToken = () => {
    localStorage.removeItem('token');
  };
  
  export const getUserRole = () => {
    return localStorage.getItem('userRole');
  };
  
  export const setUserRole = (role: string) => {
    localStorage.setItem('userRole', role);
  };
  
  export const removeUserRole = () => {
    localStorage.removeItem('userRole');
  };

  export const getUsername = () => {
    return localStorage.getItem('username');
  };
  
  export const setUsername = (username: string) => {
    localStorage.setItem('username', username);
  };
  
  export const removeUsername = () => {
    localStorage.removeItem('username');
  };