import React, { useState } from 'react';
import { Table, Button, Input, Space, Popconfirm, message, Form } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import axios from '../api/axiosInstance';

interface CrmLogin {
  _id: string;
  crmLogin: string;
  crmId: string;
}

interface Props {
  userId: string;
  logins: CrmLogin[];
  fetchUsers: () => void;
}

const CrmLoginsTable: React.FC<Props> = ({ userId, logins, fetchUsers }) => {
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editedLogin, setEditedLogin] = useState<CrmLogin | null>(null);
  const [newLogin, setNewLogin] = useState<CrmLogin>({ _id: '', crmLogin: '', crmId: '' });
  const [adding, setAdding] = useState<boolean>(false);

  // Редагування CRM логіну
  const handleEdit = (login: CrmLogin) => {
    setEditingKey(login._id);
    setEditedLogin({ ...login });
  };

  // Збереження змін у CRM логіні
  const handleSave = async () => {
    if (editedLogin) {
      try {
        await axios.put(`/api/users/${userId}/crm-logins/${editedLogin._id}`, editedLogin);
        message.success('Логін оновлено успішно');
        fetchUsers();
        setEditingKey(null);
        setEditedLogin(null);
      } catch (error) {
        message.error('Не вдалося оновити логін');
      }
    }
  };

  // Відміна редагування
  const handleCancel = () => {
    setEditingKey(null);
    setEditedLogin(null);
  };

  // Додавання нового логіну
  const handleAddLogin = async () => {
    if (newLogin.crmLogin && newLogin.crmId) {
      try {
        await axios.post(`/api/users/${userId}/crm-logins`, newLogin);
        message.success('Логін додано успішно');
        fetchUsers();
        setNewLogin({ _id: '', crmLogin: '', crmId: '' });
        setAdding(false);
      } catch (error) {
        message.error('Не вдалося додати логін');
      }
    } else {
      message.error('Будь ласка, заповніть всі поля');
    }
  };

  // Відстеження змін у полі вводу для редагування
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof CrmLogin) => {
    if (editedLogin) {
      setEditedLogin({ ...editedLogin, [field]: e.target.value });
    }
  };

  // Відстеження змін у новому логіні
  const handleNewLoginChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof CrmLogin) => {
    setNewLogin({ ...newLogin, [field]: e.target.value });
  };

  const columns = [
    {
      title: 'CRM Логін',
      dataIndex: 'crmLogin',
      key: 'crmLogin',
      render: (_: any, record: CrmLogin) => {
        return editingKey === record._id ? (
          <Input
            value={editedLogin?.crmLogin}
            onChange={(e) => handleInputChange(e, 'crmLogin')}
          />
        ) : (
          record.crmLogin
        );
      },
    },
    {
      title: 'CRM ID',
      dataIndex: 'crmId',
      key: 'crmId',
      render: (_: any, record: CrmLogin) => {
        return editingKey === record._id ? (
          <Input
            value={editedLogin?.crmId}
            onChange={(e) => handleInputChange(e, 'crmId')}
          />
        ) : (
          record.crmId
        );
      },
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (_: any, record: CrmLogin) => {
        return editingKey === record._id ? (
          <Space>
            <Button type="link" icon={<SaveOutlined />} onClick={handleSave}>
              Зберегти
            </Button>
            <Button type="link" icon={<CloseOutlined />} onClick={handleCancel}>
              Відмінити
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            >
              Редагувати
            </Button>
            <Popconfirm
              title="Видалити логін?"
              onConfirm={async () => {
                try {
                  await axios.delete(`/api/users/${userId}/crm-logins/${record._id}`);
                  message.success('Логін видалено');
                  fetchUsers();
                } catch (error) {
                  message.error('Не вдалося видалити логін');
                }
              }}
              okText="Так"
              cancelText="Ні"
            >
              <Button type="link" icon={<DeleteOutlined />} danger>
                Видалити
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table dataSource={logins} columns={columns} rowKey="_id" pagination={false} />

      {/* Рядок для додавання нового CRM логіну */}
      {adding ? (
        <Form layout="inline" onFinish={handleAddLogin} style={{ marginTop: '16px' }}>
          <Form.Item
            required
            rules={[{ required: true, message: 'Введіть CRM логін' }]}
          >
            <Input
              placeholder="CRM Логін"
              value={newLogin.crmLogin}
              onChange={(e) => handleNewLoginChange(e, 'crmLogin')}
            />
          </Form.Item>
          <Form.Item
            required
            rules={[{ required: true, message: 'Введіть CRM ID' }]}
          >
            <Input
              placeholder="CRM ID"
              value={newLogin.crmId}
              onChange={(e) => handleNewLoginChange(e, 'crmId')}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
              Додати
            </Button>
            <Button
              type="link"
              icon={<CloseOutlined />}
              onClick={() => {
                setAdding(false);
                setNewLogin({ _id: '', crmLogin: '', crmId: '' });
              }}
            >
              Відмінити
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Button
          type="dashed"
          onClick={() => setAdding(true)}
          icon={<PlusOutlined />}
          style={{ marginTop: '16px' }}
        >
          Додати новий логін
        </Button>
      )}
    </div>
  );
};

export default CrmLoginsTable;
