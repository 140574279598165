import React, { useEffect } from 'react';
import UsersTable from '../components/UsersTable';

const UsersPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Користувачі";
  }, []);

  return (
    <div>
      <h1>Користувачі</h1>
      <UsersTable></UsersTable>
    </div>
  );
};

export default UsersPage;