import React, { useEffect } from 'react';
import CapTable from '../components/CapTable';

const CapsPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Капи";
  }, []);

  return (
    <div>
      <h1>Капи</h1>
      <CapTable />
    </div>
  );
};

export default CapsPage;