import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Collapse, Button, Tag, Select, Row, Col, message, Empty, Space, Tooltip, Typography, Divider, Input, Switch } from 'antd';
import { UserOutlined, CopyOutlined } from '@ant-design/icons';
import TimeDifference from './TimeDifference';
import axios from '../api/axiosInstance';
import socket from "../socket";
import Masonry from 'react-masonry-css';

const { Panel } = Collapse;
const { Option } = Select;
const breakpointColumnsObj = {
  default: 2,
  1100: 2,
  700: 1,
};

interface LinkCards {
  country: string;
  split: number;
  groups: {
    groupId: string;
    groupName: string;
    groupColor: string;
    offers: {
      offerId: number;
      offerName: string;
      isActive: boolean;
      comment: string;
      generalCapCount: number;
      generalLeftDepCount: number;
      generalRedepDebt: number;
      generalUpdatedAt: Date;
      userDepCount: number;
      userRedepCount: number;
      userRedepDebt: number;
      userUpdateAt: Date;
      allowedSources: {
        sourceId: number;
        name: string;
      }[];
    }[];
  }[];
}

const LinkCardsComponent: React.FC = () => {
  const [linkCards, setLinkCards] = useState<LinkCards[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredCountry, setFilteredCountry] = useState<string | undefined>(undefined);
  const [filteredGroup, setFilteredGroup] = useState<string | undefined>(undefined);
  const [filteredSource, setFilteredSource] = useState<string | undefined>(undefined);
  const [filterOfferName, setFilterOfferName] = useState<string>('');
  const [openCountries, setOpenCountries] = useState<string[]>([]);
  const [openGroups, setOpenGroups] = useState<string[]>([]);
  const [capBtnLoadingStates, setCapBtnLoadingStates] = useState<{ [key: number]: boolean }>({});
  const [capBtnErrorStates, setCapBtnErrorStates] = useState<{ [key: number]: string | null }>({});
  const [linkCopyBtnStates, setLinkCopyBtnStates] = useState<{ [key: number]: { [key2: number]: string | null } }>({});
  const [isFiteredByTester, setIsFiteredByTester] = useState<boolean>(true);
  const [isTesterCheck, setIsTesterCheck] = useState<boolean>(true);
  const [tester, setTester] = useState<string>('');
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);


  const { Text } = Typography;

  const fetchLinkCards = useCallback(async () => {
    setLoading(true); // Завжди починаємо з завантаження
    try {
      const params = isFiteredByTester ? { tester } : undefined;
      const response = await axios.get('/api/links', { params });
      setLinkCards(response.data); // Оновлюємо дані
    } catch (error) {
      console.error('Error fetching link cards:', error);
      message.error('Не вдалося завантажити дані з API');
    } finally {
      setLoading(false); // Завжди завершуємо завантаження
    }
  }, [tester, isFiteredByTester]);

  useEffect(() => {
    socket.on("updateCards", async () => {
      fetchLinkCards();
    });
  });

  useEffect(() => {
    if (linkCards.length > 0) {
      setOpenCountries(linkCards.map((link) => link.country));
      setOpenGroups(linkCards.flatMap((link) => link.groups.map((group) => group.groupId)));
    }
  }, [linkCards]);

  useEffect(() => {
    fetchLinkCards();
  }, [fetchLinkCards]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      fetchLinkCards();
    }, 1000);

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [tester, fetchLinkCards]);

  const isColorDark = (hexColor: string): boolean => {
    const color = hexColor.replace('#', '');

    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness < 128;
  };

  const handleTesterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTester(event.target.value);
  };

  const handleTesterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      fetchLinkCards();
    }
  };


  const getCap = async (offerId: number) => {
    setCapBtnLoadingStates((prev) => ({ ...prev, [offerId]: true }));
    setCapBtnErrorStates((prev) => ({ ...prev, [offerId]: null }));
    try {
      const response = await axios.get('api/cap/' + offerId.toString(), {
        validateStatus: function (status) {
          return status < 600;
        },
      });

      if (response.status >= 400 && response.status < 600) {
        setCapBtnErrorStates((prev) => ({ ...prev, [offerId]: response.data.message }));
      } else if (response.data) {
        const { generalCapCount, generalLeftDepCount, generalRedepDebt, generalUpdatedAt, userDepCount, userRedepCount, userRedepDebt, userUpdateAt } = response.data;

        setLinkCards((prevLinkCards) =>
          prevLinkCards.map((linkCard) => ({
            ...linkCard,
            groups: linkCard.groups.map((group) => ({
              ...group,
              offers: group.offers.map((offer) =>
                offer.offerId === offerId
                  ? {
                    ...offer,
                    generalCapCount: generalCapCount,
                    generalLeftDepCount: generalLeftDepCount,
                    generalRedepDebt: generalRedepDebt,
                    generalUpdatedAt: generalUpdatedAt,
                    userDepCount: userDepCount,
                    userRedepCount: userRedepCount,
                    userRedepDebt: userRedepDebt,
                    userUpdateAt: userUpdateAt
                  }
                  : offer
              ),
            })),
          }))
        );
      }
    }
    catch (error) {
      setCapBtnErrorStates((prev) => ({ ...prev, [offerId]: 'Unexpected error occurred' }));
    } finally {
      setCapBtnLoadingStates((prev) => ({ ...prev, [offerId]: false }));
    }
  };

  const getLink = async (offerId: number, sourceId: number) => {
    try {
      const requestData = { offerId, sourceId, ...(isTesterCheck && { tester }) };
      const config = {
        validateStatus: (status: number) => status < 600, // Всі статуси < 600 вважаються валідними
      };

      const response = await axios.post(
        '/api/links/copy',
        requestData,
        config
      );

      if (response.status >= 400 && response.status < 600) {
        // Помилка
        setLinkCopyBtnStates((prevState) => ({
          ...prevState,
          [offerId]: {
            ...(prevState[offerId] || {}),
            [sourceId]: response.data?.message || "Помилка",
          },
        }));
      } else if (response.data?.link) {
        // Успішна відповідь
        setLinkCopyBtnStates((prevState) => ({
          ...prevState,
          [offerId]: {
            ...(prevState[offerId] || {}),
            [sourceId]: response.data.link,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching link:", error);
      setLinkCopyBtnStates((prevState) => ({
        ...prevState,
        [offerId]: {
          ...(prevState[offerId] || {}),
          [sourceId]: "Помилка при запиті",
        },
      }));
    }
  };

  const getFilteredLinkCards = () => {
    return linkCards
      .filter((card) => !filteredCountry || card.country === filteredCountry)
      .map((card) => {
        const filteredGroups = card.groups
          .filter((group) => !filteredGroup || group.groupId === filteredGroup)
          .map((group) => {
            const filteredOffers = group.offers
              .filter((offer) =>
                !filterOfferName ||
                offer.offerName.toLowerCase().includes(filterOfferName.toLowerCase().trim())
              )
              .map((offer) => {
                const filteredSources = offer.allowedSources.filter(
                  (source) => !filteredSource || source.name === filteredSource
                );

                if (filteredSources.length > 0 || !filteredSource) {
                  return { ...offer, allowedSources: filteredSources };
                } else {
                  return null;
                }
              })
              .filter((offer) => offer !== null) as typeof group.offers;

            if (filteredOffers.length > 0) {
              return { ...group, offers: filteredOffers };
            } else {
              return null;
            }
          })
          .filter((group) => group !== null) as typeof card.groups;

        if (filteredGroups.length > 0) {
          return { ...card, groups: filteredGroups };
        } else {
          return null;
        }
      })
      .filter((card) => card !== null) as LinkCards[];
  };

  const filteredLinkCards = getFilteredLinkCards();

  return (
    <div>
      <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Col
          style={{
            flex: '0 0 300px',
            paddingRight: '16px',
            position: 'sticky',
            top: '0',
            alignSelf: 'flex-start',
            height: '100vh',
            overflow: 'auto',
            background: '#f5f5f5', // Фон для тестування
          }}
        >
          <Select
            placeholder="Фільтрувати за країною"
            onChange={(value) => setFilteredCountry(value)}
            style={{ width: '100%', marginBottom: '5px' }}
            allowClear
            loading={loading}
            value={filteredCountry}
          >
            {Array.from(new Set(linkCards.map((card) => card.country))).map((country) => (
              <Option key={country} value={country}>
                {country}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Фільтрувати за групою продуктів"
            onChange={(value) => setFilteredGroup(value)}
            style={{ width: '100%', marginBottom: '5px' }}
            allowClear
            loading={loading}
            value={filteredGroup}
          >
            {Array.from(
              new Set(linkCards.flatMap((card) => card.groups.map((group) => group.groupId)))
            ).map((groupId) => (
              <Option key={groupId} value={groupId}>
                {linkCards
                  .flatMap((card) => card.groups)
                  .find((group) => group.groupId === groupId)?.groupName || 'Невідома група'}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Фільтрувати за джерелами"
            onChange={(value) => setFilteredSource(value)}
            style={{ width: '100%', marginBottom: '5px' }}
            allowClear
            loading={loading}
            value={filteredSource}
          >
            {Array.from(
              new Set(
                linkCards.flatMap((card) =>
                  card.groups.flatMap((group) =>
                    group.offers.flatMap((offer) => offer.allowedSources.map((source) => source.name))
                  )
                )
              )
            ).map((sourceName) => (
              <Option key={sourceName} value={sourceName}>
                {sourceName}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="Фільтр по назві продукту"
            value={filterOfferName}
            onChange={(e) => setFilterOfferName(e.target.value)}
            style={{ width: '100%', marginBottom: '25px' }}
          />
          <Input
          
            value={tester}
            onChange={handleTesterChange}
            onKeyDown={handleTesterKeyDown}
            placeholder="Тестер"
            prefix={<UserOutlined />}
            style={{ width: '100%', marginBottom: '5px' }}
          />
          <Row style={{ width: '100%', marginBottom: '5px' }}>
            <Col span={4}>
              <Switch defaultChecked onChange={(e) => setIsFiteredByTester(!isFiteredByTester)} />
            </Col>
            <Col span={20}>
              <span>Залишити доступні продукти для ліда</span>
            </Col>
          </Row>
          <Row style={{ width: '100%', marginBottom: '5px' }}>
            <Col span={4}>
              <Switch defaultChecked onChange={(e) => setIsTesterCheck(!isTesterCheck)} />
            </Col>
            <Col span={20}>
              <span>Видавати посилання одразу для ліда(з перевіркою)</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ flex: '1' }}>
          {!loading && filteredLinkCards.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

          {!loading &&
            filteredLinkCards.length > 0 &&
            filteredLinkCards.map((card) => (
              <Collapse key={card.country} defaultActiveKey={openCountries} accordion>
                <Panel header={
                  (card.country + " | Макс. спліт - " + card.split)} key={card.country}>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {card.groups.map((group) => (
                      <div key={group.groupId}>
                        <Collapse
                          defaultActiveKey={openGroups}
                          accordion
                          style={{ color: "#FFFFFF", marginBottom: 16 }}
                        >
                          <Panel
                            header={
                              <span style={{ color: isColorDark(group.groupColor) ? '#FFFFFF' : '#000000' }}>
                                {group.groupName}
                              </span>
                            }
                            key={group.groupId}
                            style={{
                              backgroundColor: group.groupColor,
                            }}
                          >
                            {group.offers.map((offer) => (
                              <Card
                                key={offer.offerId}
                                title={offer.offerName}
                                extra={
                                  <Space style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                      <div>
                                        <Space direction="horizontal">
                                          <Tooltip
                                            title={capBtnErrorStates[offer.offerId]}
                                            visible={!!capBtnErrorStates[offer.offerId]}
                                            afterVisibleChange={(visible) => {
                                              if (visible) {
                                                setTimeout(() => {
                                                  setCapBtnErrorStates((prev) => ({
                                                    ...prev,
                                                    [offer.offerId]: null,
                                                  }));
                                                }, 5000);
                                              }
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              onClick={() => getCap(offer.offerId)}
                                              loading={capBtnLoadingStates[offer.offerId]}
                                            >
                                              Оновити
                                            </Button>
                                          </Tooltip>
                                          {
                                            offer.generalLeftDepCount === undefined ? (
                                              <Tag color="red">Невідома кіл-ть депів</Tag>
                                            ) : offer.generalLeftDepCount > 0 ? (
                                              <Tag color="green">Активний</Tag>
                                            ) : (
                                              <Tag color="red">Тільки редепи</Tag>
                                            )
                                          }
                                        </Space>
                                      </div>
                                    </div>
                                  </Space>
                                }
                                style={{ marginBottom: 16 }}
                              >
                                <div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                      <Text type="secondary" style={{ marginRight: 10 }}>
                                        {offer.generalUpdatedAt !== undefined ? (
                                          <TimeDifference updatedAt={offer.generalUpdatedAt} />
                                        ) : null}
                                      </Text>
                                      {offer.generalCapCount !== undefined && (
                                        <>
                                          <span>Капа: {offer.generalCapCount}; </span>
                                          <span>Залишок депів: {offer.generalLeftDepCount}; </span>
                                          <span
                                            style={{
                                              color: offer.generalRedepDebt < 1 ? 'green' : 'red',
                                            }}
                                          >
                                            Борг по редепам: {offer.generalRedepDebt};
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <Text type="secondary" style={{ marginRight: 10 }}>
                                        {offer.userUpdateAt !== undefined ? (
                                          <TimeDifference updatedAt={offer.userUpdateAt} />
                                        ) : null}
                                      </Text>
                                      {offer.userDepCount !== undefined && (
                                        <>
                                          <span>Кіл-ть депів: {offer.userDepCount}; </span>
                                          <span>Кіл-ть редепів: {offer.userRedepCount}; </span>
                                          <span
                                            style={{
                                              color: offer.userRedepDebt < 1 ? 'green' : 'red',
                                            }}
                                          >
                                            Борг по редепам: {offer.userRedepDebt};
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <Divider />
                                  {offer.comment}
                                  {offer.comment != null ? <br /> : null}

                                  {offer.allowedSources.length > 0 ? (
                                    offer.allowedSources.map((source) => (
                                      <Tooltip
                                        key={source.sourceId}
                                        title={
                                          linkCopyBtnStates[offer.offerId]?.[source.sourceId] ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                maxWidth: '300px', // Обмежуємо ширину тултіпа
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-all' // або wordWrap: 'break-word'
                                              }}
                                            >
                                              <div style={{ flex: 1 }}>
                                                {linkCopyBtnStates[offer.offerId][source.sourceId]}
                                              </div>
                                              <Button
                                                type="text"
                                                icon={<CopyOutlined style={{ color: '#fff' }} />}
                                                onClick={() => {
                                                  const link = linkCopyBtnStates[offer.offerId][source.sourceId];
                                                  if (link) {
                                                    navigator.clipboard.writeText(link)
                                                      .then(() => message.success('Посилання скопійовано!'))
                                                      .catch(() => message.error('Не вдалося скопіювати посилання.'));
                                                  }
                                                }}
                                                style={{
                                                  minWidth: 24,
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                }}
                                              />
                                            </div>
                                          ) : null
                                        }
                                        visible={!!linkCopyBtnStates[offer.offerId]?.[source.sourceId]}
                                        afterVisibleChange={(visible) => {
                                          if (visible) {
                                            setTimeout(() => {
                                              setLinkCopyBtnStates((prev) => ({
                                                ...prev,
                                                [offer.offerId]: {
                                                  ...(prev[offer.offerId] || {}),
                                                  [source.sourceId]: null,
                                                },
                                              }));
                                            }, 5000);
                                          }
                                        }}
                                      >
                                        <Button
                                          style={{ marginRight: 8, marginBottom: 8 }}
                                          onClick={(e) => getLink(offer.offerId, source.sourceId)}
                                        >
                                          {source.name}
                                        </Button>
                                      </Tooltip>
                                    ))
                                  ) : (
                                    <Tag color="warning">Немає дозволених джерел</Tag>
                                  )}
                                </div>
                              </Card>
                            ))}
                          </Panel>
                        </Collapse>
                      </div>
                    ))}
                  </Masonry>


                </Panel>
              </Collapse>
            ))}
        </Col>
      </Row>
    </div >
  );
};

export default LinkCardsComponent;
