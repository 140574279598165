import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../api/axiosInstance';

const TeamTable: React.FC = () => {
  const [teams, setTeams] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<any | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTeams();
  }, []);

  // Отримати всі команди
  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/teams');
      setTeams(response.data);
    } catch (error) {
      message.error('Не вдалося отримати команди');
    } finally {
      setLoading(false);
    }
  };

  // Відкрити модальне вікно для додавання/оновлення команди
  const openModal = (team?: any) => {
    if (team) {
      setSelectedTeam(team);
      form.setFieldsValue({ name: team.name });
    } else {
      setSelectedTeam(null);
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  // Закрити модальне вікно
  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Додати або оновити команду
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (selectedTeam) {
        // Оновлення команди
        await axios.put(`/api/teams/${selectedTeam.id}`, values);
        message.success('Команду успішно оновлено');
      } else {
        // Додавання нової команди
        await axios.post('/api/teams', values);
        message.success('Команду успішно додано');
      }
      fetchTeams();
      closeModal();
    } catch (error) {
      message.error('Не вдалося зберегти команду');
    }
  };

  // Видалити команду
  const handleDelete = async (teamId: string) => {
    try {
      await axios.delete(`/api/teams/${teamId}`);
      message.success('Команду успішно видалено');
      fetchTeams();
    } catch (error) {
      message.error('Не вдалося видалити команду');
    }
  };

  // Колонки таблиці
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Назва команди',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => openModal(record)}
            style={{ marginRight: '8px' }}
          />
          <Popconfirm
            title="Ви впевнені, що хочете видалити команду?"
            onConfirm={() => handleDelete(record.id)}
            okText="Так"
            cancelText="Ні"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => openModal()}
        style={{ marginBottom: '16px' }}
      >
        Додати команду
      </Button>
      <Table
        columns={columns}
        dataSource={teams}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={selectedTeam ? 'Оновити команду' : 'Додати команду'}
        visible={isModalVisible}
        onCancel={closeModal}
        onOk={handleSubmit}
        okText={selectedTeam ? 'Оновити' : 'Додати'}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Назва команди"
            rules={[{ required: true, message: 'Будь ласка, введіть назву команди' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeamTable;
