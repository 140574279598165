// components/TimeDifference.tsx
import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface TimeDifferenceProps {
  updatedAt: Date;
}

const TimeDifference: React.FC<TimeDifferenceProps> = ({ updatedAt }) => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000); // Оновлюємо кожну хвилину (60000 мс)

    return () => clearInterval(interval); // Очищаємо інтервал при розмонтуванні
  }, []);

  const getTimeDifferenceText = (updatedAt: Date) => {
    const now = currentTime;
    const updatedDate = new Date(updatedAt).getTime();
    const differenceInMinutes = Math.floor((now - updatedDate) / (1000 * 60));

    if (differenceInMinutes < 1) {
      return `щойно`;
    } else if (differenceInMinutes < 60) {
      return `${differenceInMinutes} хвилин тому`;
    }

    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
      return `${differenceInHours} годин тому`;
    }

    const differenceInDays = Math.floor(differenceInHours / 24);
    return `${differenceInDays} днів тому`;
  };

  return (
    <Text type="secondary" style={{ marginRight: 10 }}>
      Оновлено {getTimeDifferenceText(updatedAt)}
    </Text>
  );
};

export default TimeDifference;