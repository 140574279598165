import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, message, Space, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import axios from '../api/axiosInstance';
import CrmLoginsTable from './CrmLoginsTable'; // Імпортуємо новий компонент

interface CrmLogin {
  _id: string;
  crmLogin: string;
  crmId: string;
}

interface User {
  _id: string;
  username: string;
  role: string;
  logins: CrmLogin[];
}

const { Option } = Select;

const UsersTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsers();
  }, []);

  // Функція для отримання всіх користувачів
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/users/');
      setUsers(response.data);
    } catch (error) {
      message.error('Не вдалося отримати список користувачів');
    } finally {
      setLoading(false);
    }
  };

  // Функція для відкриття модального вікна для додавання користувача
  const showAddUserModal = () => {
    setIsEditingUser(false);
    setCurrentUser(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  // Функція для відкриття модального вікна для редагування користувача
  const showEditUserModal = (user: User) => {
    setIsEditingUser(true);
    setCurrentUser(user);
    form.setFieldsValue({
      username: user.username,
      role: user.role,
      password: '',
      confirmPassword: '',
    });
    setIsModalVisible(true);
  };

  // Функція для видалення користувача
  const handleDeleteUser = async (userId: string) => {
    try {
      await axios.delete(`/api/users/${userId}`);
      message.success('Користувача видалено');
      fetchUsers();
    } catch (error) {
      message.error('Не вдалося видалити користувача');
    }
  };

  // Функція для обробки відправки форми модального вікна
  const handleModalSubmit = async (values: any) => {
    const { password, confirmPassword, ...rest } = values;

    if (isEditingUser && !password) {
      // Якщо редагується користувач і пароль не змінюється
      try {
        await axios.put(`/api/users/${currentUser?._id}`, rest);
        message.success('Користувача оновлено');
      } catch (error) {
        message.error('Не вдалося оновити користувача');
      }
    } else {
      // Перевірка, чи паролі співпадають
      if (password !== confirmPassword) {
        message.error('Паролі не співпадають');
        return;
      }

      const payload = { ...rest, password };

      try {
        if (isEditingUser && currentUser) {
          await axios.put(`/api/users/${currentUser._id}`, payload);
          message.success('Користувача оновлено');
        } else {
          await axios.post('/api/users/register', payload);
          message.success('Користувача додано');
        }
      } catch (error) {
        message.error('Не вдалося зберегти користувача');
      }
    }

    setIsModalVisible(false);
    fetchUsers();
  };

  // Функція для відкриття/закриття підтаблиці
  const handleExpand = (expanded: boolean, record: User) => {
    if (expanded) {
      setExpandedRowKeys([record._id]); // Розгортаємо тільки цей рядок
    } else {
      setExpandedRowKeys([]);
    }
  };

  // Колонки для таблиці користувачів
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Логін',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (_: any, record: User) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => showEditUserModal(record)}
          />
          <Popconfirm
            title="Видалити користувача?"
            onConfirm={() => handleDeleteUser(record._id)}
            okText="Так"
            cancelText="Ні"
          >
            <Button type="link" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showAddUserModal}
        style={{ marginBottom: 16 }}
      >
        Додати користувача
      </Button>
      <Table
        columns={columns}
        dataSource={users}
        loading={loading}
        rowKey="_id"
        expandable={{
          expandedRowRender: (record) => (
            <CrmLoginsTable
              userId={record._id}
              logins={record.logins}
              fetchUsers={fetchUsers} // Передаємо функцію оновлення користувачів для актуалізації даних після редагування
            />
          ),
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
      <Modal
        title={isEditingUser ? 'Оновити користувача' : 'Додати користувача'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <Form form={form} layout="vertical" onFinish={handleModalSubmit}>
          <Form.Item
            name="username"
            label="Логін"
            rules={[{ required: true, message: 'Введіть логін' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label="Роль"
            rules={[{ required: true, message: 'Виберіть роль' }]}
          >
            <Select placeholder="Виберіть роль">
              <Option value="admin">admin</Option>
              <Option value="user">user</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="password"
            label="Пароль"
            rules={
              !isEditingUser
                ? [{ required: true, message: 'Введіть пароль' }]
                : []
            }
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Повтор пароля"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: !isEditingUser,
                message: 'Повторіть пароль',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Паролі не співпадають'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {isEditingUser ? 'Оновити' : 'Додати'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UsersTable;
