import React, { useEffect } from 'react';
import TeamTable from '../components/TeamTable';

const TeamsPage: React.FC = () => {
  useEffect(() => {
    document.title = "DirectCRM Команди";
  }, []);

  return (
    <div>
      <h1>Команди</h1>
      <TeamTable />
    </div>
  );
};

export default TeamsPage;